// extracted by mini-css-extract-plugin
export var account = "privacy-policy-module--account--oXvQw";
export var accountMeansAUniqueAccount = "privacy-policy-module--accountMeansAUniqueAccount--mzB1n";
export var bannerSuper = "privacy-policy-module--bannerSuper--DiFLa";
export var blankLine2 = "privacy-policy-module--blankLine2--JEKVL";
export var button = "privacy-policy-module--button--xlHJx";
export var caption = "privacy-policy-module--caption--msCzb";
export var caption18 = "privacy-policy-module--caption18--k1b82";
export var ccs = "privacy-policy-module--ccs--ZP7rs";
export var cdihealthlogoIcon = "privacy-policy-module--cdihealthlogoIcon--WJhwL";
export var cdihealthlogoIcon1 = "privacy-policy-module--cdihealthlogoIcon1--rEWVG";
export var column = "privacy-policy-module--column--tajR2";
export var display = "privacy-policy-module--display--2AeDR";
export var display1 = "privacy-policy-module--display1--vD8yG";
export var docTemplate = "privacy-policy-module--docTemplate--ASDET";
export var email = "privacy-policy-module--email--hStFA";
export var emailAddress = "privacy-policy-module--emailAddress--5EPre";
export var footer = "privacy-policy-module--footer--M+871";
export var footerContent = "privacy-policy-module--footerContent--9kuNQ";
export var footerL = "privacy-policy-module--footerL--kI9rJ";
export var footerLinks = "privacy-policy-module--footerLinks--Bv700";
export var footerR = "privacy-policy-module--footerR--VsHDi";
export var fullNavBar = "privacy-policy-module--fullNavBar--DJrOh";
export var globalCareSupply = "privacy-policy-module--globalCareSupply--3au6j";
export var httpshelpxadobecomflash = "privacy-policy-module--httpshelpxadobecomflash--R0mXx";
export var iconButton = "privacy-policy-module--iconButton--uYzBM";
export var iconFilled = "privacy-policy-module--iconFilled--7PUpw";
export var infocdihealthnet = "privacy-policy-module--infocdihealthnet--gvyZV";
export var interpretation = "privacy-policy-module--interpretation--arHNd";
export var logo = "privacy-policy-module--logo--ljlq1";
export var navbarContent = "privacy-policy-module--navbarContent--D1sbK";
export var navbarContentMobile = "privacy-policy-module--navbarContentMobile--aU3HY";
export var navbarMobileR = "privacy-policy-module--navbarMobileR--PJCcz";
export var navigation = "privacy-policy-module--navigation--Bfb1V";
export var navigation1 = "privacy-policy-module--navigation1--K6am9";
export var p = "privacy-policy-module--p--xVg34";
export var p1 = "privacy-policy-module--p1--kmci7";
export var p2 = "privacy-policy-module--p2--cwaez";
export var privacyPolicy = "privacy-policy-module--privacyPolicy--7d6oQ";
export var shippingPolicy = "privacy-policy-module--shippingPolicy--ImhZv";
export var subtitle = "privacy-policy-module--subtitle--2KeGg";
export var subtitle3 = "privacy-policy-module--subtitle3--UMpct";
export var subtitleLarge = "privacy-policy-module--subtitleLarge--CHws9";
export var termsFeedPrivacy = "privacy-policy-module--termsFeedPrivacy--xWHwD";
export var termsFeedPrivacyPolicyGene = "privacy-policy-module--termsFeedPrivacyPolicyGene---fC7H";
export var textColumn = "privacy-policy-module--textColumn--xYC4V";
export var textbody = "privacy-policy-module--textbody--gW9q-";
export var textbody14 = "privacy-policy-module--textbody14--IQ7Iv";
export var textbody2 = "privacy-policy-module--textbody2--7r-bR";
export var textbody24 = "privacy-policy-module--textbody24--H8m2D";
export var textbody55 = "privacy-policy-module--textbody55--6LAFE";
export var theWordsOfWhichTheInitial = "privacy-policy-module--theWordsOfWhichTheInitial--oKyUT";
export var visaIcon = "privacy-policy-module--visaIcon--vpZLG";